.display {
  margin-top: 10rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32.5rem, 1fr));
}

.display__img > img {
  width: 100%;
  margin-bottom: 10rem;
}

.display__content {
  margin-left: 5rem;
}

.display__content--info {
  font-size: 6rem;
  line-height: 1.2;
}

.display__content--text {
  margin-top: 1.5rem;
  font-family: "Space Mono", monospace;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.7;
  width: 40rem;
}

@media (max-width: 37.5em) {
  .display__content {
    margin-left: 0;
  }
  
  .display__content--info {
    font-size: 4.8rem;
  }
  
  .display__content--text {
    font-size: 1.5rem;
  }
}

@media (max-width: 52.25em) {
  .display__content--text {
    width: auto;
  }
}
  
@media (max-width: 75em) {
  /* Styles for tab-land */
}

@media (min-width: 112.5em) {
  /* Styles for big-desktop */
}
